import { React } from "react";
import "./Home.css";
import karan from "../../assets/Core/img-karan.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import About from "../About/About";
import {
  faHtml5,
  faCss3Alt,
  faJs,
  faReact,
  faPython,
  faAws,
  faNodeJs,
} from "@fortawesome/free-brands-svg-icons";

const Home = () => {
  document.title = `Home || Karan Kumar Agrawal`;
  return (
    <>
      <div className="home-main">
        <div className="home-core">
          <img className="home-hero-img" src={karan} alt="" />
          <span className="home-hero-span">
            <div>
              <h1>Hi! I'm</h1>
              <h1 className="home-hero-name">Karan Kumar Agrawal</h1>
              <h1 id="designation">Full Stack Developer</h1>
              <h2 id="designation2">Entrepreneur | Tech Enthusiast</h2>
            </div>
            <div className="home-hero-skills">
              <FontAwesomeIcon
                className="home-hero-skill-icon"
                icon={faHtml5}
              />
              <FontAwesomeIcon
                className="home-hero-skill-icon"
                icon={faCss3Alt}
              />
              <FontAwesomeIcon className="home-hero-skill-icon" icon={faJs} />
              <FontAwesomeIcon
                className="home-hero-skill-icon"
                icon={faReact}
              />
              <FontAwesomeIcon
                className="home-hero-skill-icon"
                icon={faPython}
              />
              <FontAwesomeIcon className="home-hero-skill-icon" icon={faAws} />
              <FontAwesomeIcon
                className="home-hero-skill-icon"
                icon={faNodeJs}
              />
            </div>
          </span>
        </div>

        <div className="home-social-container">
          <div className="main">
            <div className="up">
              <a
                href="https://www.linkedin.com/in/karan-agrawal25/"
                target="_blank"
                rel="noreferrer"
              >
                <button className="social-card card1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="linkedin"
                    height="1.6em"
                    viewBox="0 0 448 512"
                  >
                    <path d="M100.3 448H7.4V148.9h92.9zM53.8 108.1C24.1 108.1 0 83.5 0 53.8a53.8 53.8 0 0 1 107.6 0c0 29.7-24.1 54.3-53.8 54.3zM447.9 448h-92.7V302.4c0-34.7-.7-79.2-48.3-79.2-48.3 0-55.7 37.7-55.7 76.7V448h-92.8V148.9h89.1v40.8h1.3c12.4-23.5 42.7-48.3 87.9-48.3 94 0 111.3 61.9 111.3 142.3V448z"></path>
                  </svg>
                </button>
              </a>
              <a
                href="https://x.com/karann__25"
                target="_blank"
                rel="noreferrer"
              >
                <button className="social-card card2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 48 48"
                    width="30px"
                    height="30px"
                    className="twitter"
                  >
                    <path d="M42,12.429c-1.323,0.586-2.746,0.977-4.247,1.162c1.526-0.906,2.7-2.351,3.251-4.058c-1.428,0.837-3.01,1.452-4.693,1.776C34.967,9.884,33.05,9,30.926,9c-4.08,0-7.387,3.278-7.387,7.32c0,0.572,0.067,1.129,0.193,1.67c-6.138-0.308-11.582-3.226-15.224-7.654c-0.64,1.082-1,2.349-1,3.686c0,2.541,1.301,4.778,3.285,6.096c-1.211-0.037-2.351-0.374-3.349-0.914c0,0.022,0,0.055,0,0.086c0,3.551,2.547,6.508,5.923,7.181c-0.617,0.169-1.269,0.263-1.941,0.263c-0.477,0-0.942-0.054-1.392-0.135c0.94,2.902,3.667,5.023,6.898,5.086c-2.528,1.96-5.712,3.134-9.174,3.134c-0.598,0-1.183-0.034-1.761-0.104C9.268,36.786,13.152,38,17.321,38c13.585,0,21.017-11.156,21.017-20.834c0-0.317-0.01-0.633-0.025-0.945C39.763,15.197,41.013,13.905,42,12.429"></path>
                  </svg>
                </button>
              </a>
            </div>
            <div className="down">
              <a
                href="https://github.com/KDrop25"
                target="_blank"
                rel="noreferrer"
              >
                <button className="social-card card3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 30 30"
                    width="30px"
                    height="30px"
                    className="github"
                  >
                    {" "}
                    <path d="M15,3C8.373,3,3,8.373,3,15c0,5.623,3.872,10.328,9.092,11.63C12.036,26.468,12,26.28,12,26.047v-2.051 c-0.487,0-1.303,0-1.508,0c-0.821,0-1.551-0.353-1.905-1.009c-0.393-0.729-0.461-1.844-1.435-2.526 c-0.289-0.227-0.069-0.486,0.264-0.451c0.615,0.174,1.125,0.596,1.605,1.222c0.478,0.627,0.703,0.769,1.596,0.769 c0.433,0,1.081-0.025,1.691-0.121c0.328-0.833,0.895-1.6,1.588-1.962c-3.996-0.411-5.903-2.399-5.903-5.098 c0-1.162,0.495-2.286,1.336-3.233C9.053,10.647,8.706,8.73,9.435,8c1.798,0,2.885,1.166,3.146,1.481C13.477,9.174,14.461,9,15.495,9 c1.036,0,2.024,0.174,2.922,0.483C18.675,9.17,19.763,8,21.565,8c0.732,0.731,0.381,2.656,0.102,3.594 c0.836,0.945,1.328,2.066,1.328,3.226c0,2.697-1.904,4.684-5.894,5.097C18.199,20.49,19,22.1,19,23.313v2.734 c0,0.104-0.023,0.179-0.035,0.268C23.641,24.676,27,20.236,27,15C27,8.373,21.627,3,15,3z"></path>
                  </svg>
                </button>
              </a>
              <a
                href="mailto:karan25.pvt@gmail.com"
                target="_blank"
                rel="noreferrer"
              >
                <button className="social-card card4">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    height="24"
                    className="gmail"
                    width="24"
                  >
                    <path d="M6 12C6 15.3137 8.68629 18 12 18C14.6124 18 16.8349 16.3304 17.6586 14H12V10H21.8047V14H21.8C20.8734 18.5645 16.8379 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C15.445 2 18.4831 3.742 20.2815 6.39318L17.0039 8.68815C15.9296 7.06812 14.0895 6 12 6C8.68629 6 6 8.68629 6 12Z"></path>
                  </svg>
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
      <About />
    </>
  );
};

export default Home;
