import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Registration-Form.css";
import miragelogo from "../../assets/Core/thinkathon-logo.png";
import axios from "../../api/axios";

const REGISTRATION_URL = "/contactme";

const Registration = () => {
  const [success, setSuccess] = useState(false);
  const [err, setErr] = useState("");
  const [name, setName] = useState("");
  const [desc, setDesc] = useState("");
  const [subject, setSubject] = useState("");
  const [email, setEmail] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent the default form submission behavior

    try {
      const response = await axios.post(
        REGISTRATION_URL,
        JSON.stringify({ email, name, desc, subject }),
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      );
      console.log(response);
      setSuccess(true);
      setEmail("");
      setName("");
      setDesc("");
      setSubject("");
    } catch (err) {
      if (!err?.response) {
        setErr("No Server Response");
        console.log("No Server Response");
      } else {
        setErr("Registration Failed");
        console.log("Registration Failed");
      }
    }
  };

  return (
    <div className="registration-main">
      <form className="registration-form" onSubmit={handleSubmit}>
        <h1 className="registration-regform">Contact Me</h1>
        <div className="main-container">
          <div className="registration-inner-regform">
            <input
              className="registration-input"
              name="name"
              type="text"
              placeholder="Your Name*"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
            <input
              className="registration-input"
              name="email"
              type="email"
              placeholder="E-mail*"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <input
            className="registration-input"
            name="subject"
            type="text"
            placeholder="Subject*"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
            required
          />
          <textarea
            className="registration-input registration-textarea"
            name="desc"
            placeholder="Description"
            value={desc}
            onChange={(e) => setDesc(e.target.value)}
          />
        </div>
        <hr />
        <div className="registration-radio-flexbtn">
          <button className="registration-submit-btn" type="submit">
            Send
          </button>
        </div>
      </form>
      {success && (
        <p className="registration-success">
          Your message has been sent successfully!
        </p>
      )}
      {err && <p className="registration-error">{err}</p>}
    </div>
  );
};

export default Registration;
