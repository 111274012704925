import React, { useEffect, useState } from "react";
import "./Events.css";
import { Link } from "react-router-dom";
import axios from "../../api/axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGithub } from "@fortawesome/free-brands-svg-icons";

const DATA_URL = "/data";

const Events = () => {
  const [projects, setProjects] = useState([]);
  document.title = `Projects || Karan Kumar Agrawal`;

  const fetchProjectData = async () => {
    try {
      const response = await axios.get(`${DATA_URL}/projects`);
      setProjects(response.data);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    fetchProjectData();
  }, []);

  // Get unique project types and place "Miscellaneous" at the end
  const projectTypes = [...new Set(projects.map((event) => event.type))].sort(
    (a, b) => (a === "Miscellaneous" ? 1 : b === "Miscellaneous" ? -1 : 0)
  );

  return (
    <div className="events-main">
      <h1 className="events-heading-text">My Projects</h1>
      {projectTypes.map((type, index) => (
        <div className="events-core" key={index}>
          <div className="events-heading">
            <hr />
            <h1 className="events-heading-text" id="project-type">
              {type}
            </h1>
            <hr />
          </div>
          <div className="events-cards-row">
            {projects
              .filter((item) => item.type === type)
              .map((card, index) => (
                <div className="events-vertical-card" key={index}>
                  {card.icon && (
                    <div className="events-vertical-card-top">
                      <img
                        className="events-vertical-card-userimage"
                        src={card.icon}
                        alt=""
                      />
                    </div>
                  )}
                  <div className="events-vertical-card-bottom">
                    <h1 className="events-vertical-name-text">{card.name}</h1>
                    <p className="events-vertical-name-desc">{card.headline}</p>
                  </div>
                  <div className="events-buttons">
                    <Link
                      to={`/projects/${card.name
                        .toLowerCase()
                        .replace(/\s+/g, "")}`}
                    >
                      <button className="events-button" type="button">
                        Learn More
                      </button>
                    </Link>
                    {card.link && (
                      <a
                        href={card.link}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <button className="events-button" type="button">
                          See Live
                        </button>
                      </a>
                    )}
                    {type !== "Websites" && card.github && (
                      <Link to={card.github}>
                        <FontAwesomeIcon
                          className="events-github-button"
                          type="button"
                          icon={faGithub}
                        />
                      </Link>
                    )}
                  </div>
                </div>
              ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Events;
